import { AccordionDetails, AccordionSummary, Accordion as MuiAccordion, type SxProps } from '@mui/material'
import { Icon } from '@orus.eu/pharaoh'
import type { ReactNode } from 'react'

type AccordionProps = {
  summary: ReactNode
  details: ReactNode
  ariaLabel?: string
  sx?: SxProps
  summarySx?: SxProps
  detailsSx?: SxProps
}

export function Accordion({ summary, details, sx, summarySx, detailsSx, ariaLabel }: AccordionProps): JSX.Element {
  return (
    <MuiAccordion disableGutters={true} sx={sx} aria-label={ariaLabel}>
      <AccordionSummary
        expandIcon={<Icon icon="angle-down-solid" />}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          ...summarySx,
        }}
      >
        {summary}
      </AccordionSummary>
      <AccordionDetails sx={detailsSx}>{details}</AccordionDetails>
    </MuiAccordion>
  )
}
