import type { BackofficeContractDescription } from '@orus.eu/backend/src/views/backoffice-contract-view'
import { checkDefinedAndNotNull } from '@orus.eu/error'
import { DownloadButtonWithHref, getFileDisplayName, useLanguage } from '@orus.eu/pharaoh'
import { memo } from 'react'
import { dynamicFileNames } from '../../../../../lib/download-filenames'
import { getAdminContractDownloadLink } from '../../../../../lib/download-links'
import { EndorsementDetailRow } from './endorsement-detail-row'
import { EndorsementSectionAccordion } from './endorsement-section-accordion'

type InitialVersionEndorsementSectionAccordionProps = {
  contract: BackofficeContractDescription
  customerEmail: string
}

export const InitialVersionAccordion = memo<InitialVersionEndorsementSectionAccordionProps>(
  function InitialVersionEndorsementSectionAccordion({ contract, customerEmail }) {
    const language = useLanguage()
    const downloadLink = getAdminContractDownloadLink(contract.subscriptionId, contract.versions[0].signature.id)
    const originalFileName = dynamicFileNames['agreed-terms'](customerEmail, language)
    const fileNameWithSuffix = `${getFileDisplayName(originalFileName)} - Version initiale.pdf`

    return (
      <EndorsementSectionAccordion
        title="Version initiale"
        ariaLabel="Version initiale"
        contractVersion={contract.versions[0]}
        paymentRecurrence={checkDefinedAndNotNull(contract.versions[0].dimensions.paymentRecurrence)}
      >
        <EndorsementDetailRow title="Document Signé">
          <DownloadButtonWithHref variant="secondary" size="small" href={downloadLink} fileName={fileNameWithSuffix} />
        </EndorsementDetailRow>
      </EndorsementSectionAccordion>
    )
  },
)
