import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { paymentRecurrenceLabel, type PaymentRecurrence } from '@orus.eu/dimensions'
import { Divider, Text, borderRadius, colors, controlBorder, spaceBetweenFormFields, spacing } from '@orus.eu/pharaoh'
import { memo, type ReactNode } from 'react'
import { Accordion } from '../../../../molecules/accordion'
import { Tag } from '../../../../molecules/tag'
import { ContractVersionYearlyPremiumText } from '../../../../organisms/backoffice-contract-card/contract-cart-price-row'
import { endorsementColumnWidth } from './endorsement-layout-constants'

import type { BackofficeContractVersionDescription } from '@orus.eu/backend/src/views/backoffice-contract-view'
type EndorsementSectionAccordionProps = {
  title: string
  ariaLabel: string
  contractVersion: BackofficeContractVersionDescription
  paymentRecurrence: PaymentRecurrence
  children: ReactNode
}

export const EndorsementSectionAccordion = memo<EndorsementSectionAccordionProps>(function EndorsementSectionAccordion({
  title,
  ariaLabel,
  contractVersion,
  paymentRecurrence,
  children,
}) {
  return (
    <Accordion
      summary={
        <SummaryContainer>
          <SummaryTitleColumn>
            <Text variant="body2Semibold">{title}</Text>
          </SummaryTitleColumn>

          <SummaryAmountColumn>
            <ContractVersionYearlyPremiumText version={contractVersion} />
          </SummaryAmountColumn>

          <Tag
            variant="colorScale"
            text={paymentRecurrenceLabel.feminine.capitalized[paymentRecurrence]}
            colorScale={colors.gray}
            sx={{ marginLeft: spaceBetweenFormFields }}
          />
        </SummaryContainer>
      }
      details={
        <BodyWrapper>
          <Divider
            orientation="vertical"
            css={css`
              margin-bottom: ${spacing[30]};
            `}
          />
          {children}
        </BodyWrapper>
      }
      sx={{
        borderRadius: borderRadius[20],
        border: controlBorder,
        '&:before': { backgroundColor: 'transparent' },
        padding: `${spacing[30]} ${spacing[60]} ${spacing[30]} ${spacing[60]}`,
      }}
      summarySx={{
        borderRadius: borderRadius[20],
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': { transform: 'rotate(90deg)' },
        padding: `0px ${spacing[20]} 0px ${spacing[20]}`,
      }}
      detailsSx={{
        padding: `${spacing[30]} ${spacing[20]} 0px ${spacing[20]}`,
      }}
      ariaLabel={ariaLabel}
    />
  )
})

const SummaryContainer = styled.div`
  display: flex;
  gap: ${spaceBetweenFormFields};
`

const SummaryTitleColumn = styled.div`
  width: ${endorsementColumnWidth};
  display: flex;
  align-items: center;
`
const SummaryAmountColumn = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
`

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
