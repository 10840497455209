import styled from '@emotion/styled'
import { css, Divider } from '@mui/material'
import type { Amount } from '@orus.eu/amount'
import { amountToString, areAmountsEqual } from '@orus.eu/amount'
import type { GuaranteesDescription } from '@orus.eu/backend/src/routers/contracts'
import type {
  BackofficeContractDescription,
  BackofficeContractVersionDescription,
} from '@orus.eu/backend/src/views/backoffice-contract-view'
import type { SubscriptionDimensionnedState } from '@orus.eu/backend/src/views/subscriptions/subscription-state-manager'
import type { CustomerInformation } from '@orus.eu/backend/src/views/user-account-view'
import { formatCompanyIdNumber } from '@orus.eu/company-id-number'
import type { EmbeddingPartner, PaymentRecurrence } from '@orus.eu/dimensions'
import { checkDefinedAndNotNull } from '@orus.eu/error'
import {
  ButtonLink,
  colors,
  ContentContainerBackoffice,
  controlBorder,
  DownloadButtonWithHref,
  FlexColumn,
  FlexContainer,
  FlexSpacedColumn,
  getFileDisplayName,
  Icon,
  mobileMediaQuery,
  spacing,
  Text,
  useLanguage,
} from '@orus.eu/pharaoh'
import type { Language } from '@orus.eu/translations'
import { memo, useMemo } from 'react'
import { trpcReact } from '../../../../../client'
import { dynamicFileNames } from '../../../../../lib/download-filenames'
import { getAdminContractDownloadLink } from '../../../../../lib/download-links'
import { getEmbeddingPartnerConfiguration } from '../../../../../lib/embedding-partner'
import { formatTimestamp } from '../../../../../lib/format'
import { usePermissions } from '../../../../../lib/use-permissions'
import { BackofficeSubsectionTitle } from '../../../../atoms/backoffice-subsection-title'
import { BackofficeDataCard, DataList, DataRow } from '../../../../molecules/backoffice-data-card'
import { GlobalLoadingState } from '../../../../molecules/global-loading-state'
import { BackofficeContractCard } from '../../../../organisms/backoffice-contract-card'
import { BackofficeCustomerCard } from '../../../../organisms/backoffice-customer-card'
import { BackofficeSubscriptionInvoicingConfigurationCard } from '../../../../organisms/backoffice-subscription-invoing-configuration'
import { ChipOrganizations } from '../../../../organisms/chip-organisations'
import { GuaranteeList } from '../../../../organisms/guarantee/guarantee-list'
import { OptionalGuaranteeList } from '../../../../organisms/guarantee/option-list'
import { InvoiceList } from '../../../../organisms/invoicing/invoice-list'
import { EndorsementDetailRow } from '../../common/backoffice-contract-page/endorsement-detail-row'
import {
  endorsementColumnWidth,
  endorsementValueWidth,
} from '../../common/backoffice-contract-page/endorsement-layout-constants'
import { EndorsementSectionAccordion } from '../../common/backoffice-contract-page/endorsement-section-accordion'
import { InitialVersionAccordion } from '../../common/backoffice-contract-page/initial-version-endorsement-section-accordion'

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${spacing[70]};
  ${mobileMediaQuery} {
    margin: ${spacing[50]} ${spacing[70]} 0 ${spacing[70]};
  }
`

export type BackofficeUsage = 'platform' | 'partner'

type ContractPageContentProps = {
  type: BackofficeUsage
  contract: BackofficeContractDescription
  customer: CustomerInformation
  guaranteesDescription: GuaranteesDescription
}

export const ContractPageContent = memo<ContractPageContentProps>(function ContractPageContent({
  type,
  contract,
  customer,
  guaranteesDescription,
}) {
  const { permissions } = usePermissions()
  const { data: createdByOperator, isLoading } = trpcReact.contracts.getContractCreatedByOperator.useQuery(
    contract.subscriptionId,
  )

  return (
    <ContentContainerBackoffice>
      <Header>
        <Text variant="h4">{contract.displayName}</Text>
      </Header>

      <EmbeddingInsuranceText embeddingPartner={contract.embeddingPartner} />

      <BackofficeCustomerCard customer={customer} showUserLink />

      <BackofficeDataCard title="Apporteur d’affaires" icon="briefcase-regular">
        <DataList>
          <DataRow>
            <Text variant="body2" color={colors.gray[700]}>
              Courtier
            </Text>
            <ChipOrganizations organizations={[contract.versions[0].dimensions.organization]} />
          </DataRow>
          <DataRow>
            <Text variant="body2" color={colors.gray[700]}>
              Responsable du contrat
            </Text>
            <Text variant="body2Medium">
              {!isLoading && !createdByOperator ? 'N/A' : !createdByOperator ? ' ' : createdByOperator.email}
            </Text>
          </DataRow>
        </DataList>
      </BackofficeDataCard>

      <BackofficeContractCard contract={contract} customerEmail={customer.email} type={type} />

      <BackofficeSubsectionTitle>Avenants</BackofficeSubsectionTitle>

      {type === 'platform' && permissions.includes('endorsement.create') ? (
        <ButtonLink
          css={css`
            align-self: flex-end;
          `}
          variant="secondary"
          size="small"
          icon="plus-solid"
          to="/bak/v2-pending-subscriptions/$subscriptionId"
          params={{ subscriptionId: contract.subscriptionId }}
        >
          Nouvel avenant
        </ButtonLink>
      ) : null}

      <EndorsementsSection contract={contract} customerEmail={customer.email} />

      {type === 'platform' && permissions.includes('invoices.read') ? (
        <div>
          <BackofficeSubsectionTitle>Factures</BackofficeSubsectionTitle>

          {permissions.includes('invoices.create') ? (
            <BackofficeSubscriptionInvoicingConfigurationCard subscriptionId={contract.subscriptionId} />
          ) : null}

          <div
            css={css`
              text-align: right;
              margin-top: ${spacing[70]};
              margin-bottom: ${spacing[50]};
            `}
          >
            {permissions.includes('invoices.create') ? (
              <ButtonLink
                variant="secondary"
                size="small"
                icon="plus-solid"
                to="/bak/contracts/$subscriptionId/new-invoice"
                params={{ subscriptionId: contract.subscriptionId }}
              >
                Nouvelle facture
              </ButtonLink>
            ) : null}
          </div>

          <ContractInvoicesList subscriptionId={contract.subscriptionId} />
        </div>
      ) : undefined}

      <BackofficeSubsectionTitle>Tableau des garanties</BackofficeSubsectionTitle>
      <ContractGuaranteeList guaranteesDescription={guaranteesDescription} />
    </ContentContainerBackoffice>
  )
})

const ContractInvoicesList = memo<{ subscriptionId: string }>(function ContractInvoicesList({ subscriptionId }) {
  const { isLoading, data } = trpcReact.invoices.listInvoices.useQuery(subscriptionId)
  if (isLoading) return <GlobalLoadingState />
  if (!data) return undefined
  return <InvoiceList invoicingItems={data} />
})

type Change = {
  variable: string
  beforeChangeFormattedValue: string
  afterChangeFormattedValue: string
}

type BackofficeEndorsement = {
  signatureId: string
  signatureTimestamp: number
  startTimestamp: number
  paymentAmount: Amount
  paymentRecurrence: PaymentRecurrence
  fileName: string
  downloadLink: string
  comment: string | undefined
  changes: Change[]
  contractVersion: BackofficeContractVersionDescription
}

function getEndorsementsFromContract(
  contract: BackofficeContractDescription,
  customerEmail: string,
  language: Language,
): BackofficeEndorsement[] {
  const endorsements: BackofficeEndorsement[] = []
  for (let i = 1; i < contract.versions.length; i++) {
    const changes = computeChanges(contract.versions[i - 1], contract.versions[i], language)

    endorsements.push({
      signatureId: contract.versions[i].signature.id,
      signatureTimestamp: contract.versions[i].signature.timestamp,
      startTimestamp: contract.versions[i].startTimestamp,
      paymentAmount: contract.versions[i].expectedYearlyPremiums.firstYear,
      paymentRecurrence: checkDefinedAndNotNull(contract.versions[i].dimensions.paymentRecurrence),
      fileName: dynamicFileNames['agreed-terms'](customerEmail, language),
      downloadLink: getAdminContractDownloadLink(contract.subscriptionId, contract.versions[i].signature.id),
      comment: contract.versions[i].comment,
      changes,
      contractVersion: contract.versions[i],
    })
  }

  return endorsements
}

type FieldConfiguration<VALUE> = {
  name: string
  formatter: (value: VALUE, language: Language) => string | undefined
}

function computeChanges(
  version1: BackofficeContractVersionDescription,
  version2: BackofficeContractVersionDescription,
  language: Language,
): Change[] {
  const changes: Change[] = []

  for (const [key, formatter] of Object.entries(dimensionsFormattersForDiff)) {
    const dimensionName = key as keyof SubscriptionDimensionnedState
    const formattedValue1 = formatDimensionValue(version1.dimensions, dimensionName, language)
    const formattedValue2 = formatDimensionValue(version2.dimensions, dimensionName, language)
    if (formattedValue1 !== formattedValue2) {
      changes.push({
        variable: formatter?.name ?? dimensionName,
        beforeChangeFormattedValue: formattedValue1 ?? '',
        afterChangeFormattedValue: formattedValue2 ?? '',
      })
    }
  }

  const firstYearExpectedYearlyTotalPremium1 = version1.expectedYearlyPremiums.firstYear
  const firstYearExpectedYearlyTotalPremium2 = version2.expectedYearlyPremiums.firstYear
  if (!areAmountsEqual(firstYearExpectedYearlyTotalPremium1, firstYearExpectedYearlyTotalPremium2)) {
    changes.push({
      variable: 'Prix (première année)',
      beforeChangeFormattedValue: formatAmount(firstYearExpectedYearlyTotalPremium1),
      afterChangeFormattedValue: formatAmount(firstYearExpectedYearlyTotalPremium2),
    })
  }

  const subsequentYearExpectedYearlyTotalPremium1 = version1.expectedYearlyPremiums.subsequentYears
  const subsequentYearExpectedYearlyTotalPremium2 = version2.expectedYearlyPremiums.subsequentYears
  if (!areAmountsEqual(subsequentYearExpectedYearlyTotalPremium1, subsequentYearExpectedYearlyTotalPremium2)) {
    changes.push({
      variable: 'Prix (années suivantes)',
      beforeChangeFormattedValue: formatAmount(subsequentYearExpectedYearlyTotalPremium1),
      afterChangeFormattedValue: formatAmount(subsequentYearExpectedYearlyTotalPremium2),
    })
  }

  return changes
}

function formatAmount(amount: Amount) {
  return amountToString(amount, { displayDecimals: true, addCurrency: true })
}

const dimensionsFormattersForDiff: {
  [key in keyof SubscriptionDimensionnedState]?: FieldConfiguration<SubscriptionDimensionnedState[key]>
} = {
  companyName: { name: 'Raison sociale', formatter: formatString },
  companyAddress: { name: 'Addresse', formatter: formatString },
  personalAddress: { name: 'Address perso', formatter: formatString },
  companyIdNumber: {
    name: 'Company ID Number',
    formatter: (companyIdNumber, language) =>
      companyIdNumber ? formatCompanyIdNumber(companyIdNumber, language) : undefined,
  },
  activity: { name: 'Activité', formatter: (activity) => activity?.displayName ?? undefined },
  allOptions: { name: 'Options', formatter: (options) => options?.join(', ') ?? undefined },
}

function formatDimensionValue<K extends keyof SubscriptionDimensionnedState>(
  state: SubscriptionDimensionnedState,
  dimensionName: K,
  language: Language,
): string | undefined {
  return dimensionsFormattersForDiff[dimensionName]?.formatter(state[dimensionName], language) ?? undefined
}

function formatString(value: string | null | undefined): string | undefined {
  return value ?? undefined
}

const NoContentContainer = styled(FlexContainer)`
  width: 100%;
  display: flex;
  justify-content: center;
  border: ${controlBorder};
  border-radius: ${spacing[30]};
  padding: ${spacing[20]};
`

const NoContentText = styled(Text)`
  text-align: center;
  padding: ${spacing[50]};
`

const EndorsementsSection = memo<{ contract: BackofficeContractDescription; customerEmail: string }>(
  function EndorsementsSection({ contract, customerEmail }) {
    const language = useLanguage()
    const endorsements = getEndorsementsFromContract(contract, customerEmail, language)

    if (endorsements.length === 0)
      return (
        <NoContentContainer>
          <NoContentText variant="body2" color={colors.gray[500]}>
            Aucun avenant disponible
          </NoContentText>
        </NoContentContainer>
      )

    return (
      <FlexSpacedColumn gap={spacing[30]}>
        {endorsements.map((endorsement, index) => (
          <EndorsementAccordion key={endorsement.signatureId} endorsement={endorsement} endorsementIndex={index + 1} />
        ))}
        <InitialVersionAccordion contract={contract} customerEmail={customerEmail} />
      </FlexSpacedColumn>
    )
  },
)

type EndorsementAccordionProps = {
  endorsement: BackofficeEndorsement
  /**
   * One-based index of the endorsement (1 for the first endorsement, etc...)
   */
  endorsementIndex: number
}

function EndorsementAccordion({ endorsement, endorsementIndex }: EndorsementAccordionProps): JSX.Element {
  const formattedStartEffectDatetime = formatTimestamp(endorsement.startTimestamp)

  const endorsementFileDisplayName = `${getFileDisplayName(endorsement.fileName)} - Avenant ${endorsementIndex}`
  const details = (
    <FlexColumn>
      <Divider orientation="vertical" sx={{ marginBottom: spacing[30] }} />
      <EndorsementDetailRow title={'Document Signé'}>
        <DownloadButtonWithHref
          variant="secondary"
          size="small"
          href={endorsement.downloadLink}
          fileName={endorsement.fileName}
          displayName={endorsementFileDisplayName}
          ariaLabel={`Avenant ${endorsementIndex}`}
        />
      </EndorsementDetailRow>

      <EndorsementDetailRow title={'Détails'}>
        <Text variant="body2">{endorsement.comment}</Text>
      </EndorsementDetailRow>

      <EndorsementDetailRow title={'Valeurs'}>
        <FlexSpacedColumn gap={spacing[20]}>
          {endorsement.changes.map((change) => (
            <EndorsementChangeRow key={change.variable}>
              <EndorsementChangeColumn>
                <Text variant="body2Medium">{change.variable}</Text>
              </EndorsementChangeColumn>
              <EndorsementChangeBeforeValue>
                <Text variant="body2" color={colors.gray[500]}>
                  {change.beforeChangeFormattedValue}
                </Text>
              </EndorsementChangeBeforeValue>
              <EndorsementChangeIcon>
                <Icon icon={'arrow-right-regular'} />
              </EndorsementChangeIcon>
              <EndorsementChangeAfterValue>
                <Text variant="body2" color={colors.gray[900]}>
                  {change.afterChangeFormattedValue}
                </Text>
              </EndorsementChangeAfterValue>
            </EndorsementChangeRow>
          ))}
        </FlexSpacedColumn>
      </EndorsementDetailRow>
    </FlexColumn>
  )

  return (
    <EndorsementSectionAccordion
      title={formattedStartEffectDatetime}
      contractVersion={endorsement.contractVersion}
      paymentRecurrence={endorsement.paymentRecurrence}
      ariaLabel={`Avenant ${endorsementIndex}`}
    >
      {details}
    </EndorsementSectionAccordion>
  )
}

const EndorsementChangeRow = styled.div`
  display: flex;
`

const EndorsementChangeColumn = styled.div`
  display: flex;
  align-items: center;
  width: ${endorsementColumnWidth};
`

const EndorsementChangeBeforeValue = styled.div`
  display: flex;
  align-items: center;
  width: ${endorsementValueWidth};
`

const EndorsementChangeIcon = styled.div`
  display: flex;
  align-items: center;
  width: ${spacing[50]};
`

const EndorsementChangeAfterValue = styled.div`
  display: flex;
  align-items: center;
  width: ${endorsementValueWidth};
  margin-left: ${spacing[50]};
`

const ContractGuaranteeList = memo<{ guaranteesDescription: GuaranteesDescription }>(function ContractGuaranteeList({
  guaranteesDescription,
}) {
  const activeOptions = useMemo(
    () => guaranteesDescription.optionalGuaranteesDescription.filter((option) => option.isActive),
    [guaranteesDescription.optionalGuaranteesDescription],
  )

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: ${spacing[50]};
      `}
    >
      <Text variant="subtitle1">Garanties incluses</Text>
      <GuaranteeList guarantees={guaranteesDescription.guaranteesDescription} />

      <Text variant="subtitle1">Options activées</Text>
      {activeOptions.length === 0 ? (
        <Text variant="body2">Aucune option active</Text>
      ) : (
        <OptionalGuaranteeList optionalGuarantees={activeOptions} />
      )}
    </div>
  )
})

type EmbeddingInsuranceTextProps = {
  embeddingPartner: EmbeddingPartner
}
const EmbeddingInsuranceText = memo<EmbeddingInsuranceTextProps>(function EmbeddingInsuranceText({ embeddingPartner }) {
  return embeddingPartner === 'none' ? (
    <></>
  ) : (
    <Text variant="body2" color={colors.gray[500]}>
      Créé par {getEmbeddingPartnerConfiguration(embeddingPartner.id).name} (embedded insurance)
    </Text>
  )
})
