import styled from '@emotion/styled'
import { Text, spaceBetweenFormFields, spacing } from '@orus.eu/pharaoh'
import { memo, type ReactNode } from 'react'
import { endorsementColumnWidth } from './endorsement-layout-constants'

export const EndorsementDetailRow = memo<{
  title: string
  children: ReactNode
}>(function EndorsementDetailRow({ title, children }) {
  return (
    <FlexContainer>
      <TitleColumn>
        <Text variant="body2Semibold">{title}</Text>
      </TitleColumn>
      {children}
    </FlexContainer>
  )
})

const FlexContainer = styled.div`
  display: flex;
  gap: ${spaceBetweenFormFields};
  padding-top: ${spacing[50]};
  padding-bottom: ${spacing[50]};
`

const TitleColumn = styled.div`
  width: ${endorsementColumnWidth};
  display: flex;
  align-items: center;
`
